import React from 'react';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import Alert from '../Alert';
import EmailInput from '../EmailInput';

import './Signup.css';

import { FormattedMessage } from 'react-intl';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { defineMessages, injectIntl } from 'src/i18n';
import env from 'src/stores/EnvironmentStore';
import { SubscriptionTrackerStore } from 'src/subs';
import injectStore from 'src/util/injectStore';
import { getURLQueryParam } from 'src/util/url';

const baseUrl = `https://${env.appProxyHost}`;

const messages = defineMessages({
  title: {
    id: 'signup.title',
    defaultMessage: 'Create an Account'
  },
  description: {
    id: 'signup.description',
    defaultMessage: 'Create your Invoice Simple account, and view your invoices on any device.'
  },
  header: {
    id: 'signup.header',
    defaultMessage: 'Sign Up'
  },
  subHeader: {
    id: 'signup.body',
    defaultMessage: "You're a few seconds away from your FREE Invoice Simple account!"
  },
  formLabelFirstName: {
    id: 'signup.form.labels.firstName',
    defaultMessage: 'First Name'
  },
  formLabelLastName: {
    id: 'signup.form.labels.lastName',
    defaultMessage: 'Last Name'
  },
  formLabelEmail: {
    id: 'signup.form.labels.email',
    defaultMessage: 'Email'
  },
  formLabelPassword: {
    id: 'signup.form.labels.password',
    defaultMessage: 'Password'
  },
  formPlaceholderFirstName: {
    id: 'signup.form.placeholders.firstName',
    defaultMessage: 'Johnny'
  },
  formPlaceholderLastName: {
    id: 'signup.form.placeholders.lastName',
    defaultMessage: 'Appleseed'
  },
  formPlaceholderEmail: {
    id: 'signup.form.placeholders.email',
    defaultMessage: 'name@example.com'
  },
  formButtonLabel: {
    id: 'signup.form.button.label',
    defaultMessage: 'Sign up'
  },
  formButtonLabelPending: {
    id: 'signup.form.button.label.pending',
    defaultMessage: 'Creating Account...'
  },
  formButtonLabelLoading: {
    id: 'signup.form.button.label.loading',
    defaultMessage: 'Loading...'
  },
  cancelButtonLabel: {
    id: 'signup.cancel.button.label',
    defaultMessage: 'Cancel'
  },
  loginLinkQuestion: {
    id: 'signup.link.login.question',
    defaultMessage: 'Already have an account?'
  },
  loginLinkLabel: {
    id: 'signup.link.login.label',
    defaultMessage: 'Login'
  },
  signupTermsAgreement: {
    id: 'signup.terms.agreement',
    defaultMessage:
      'By signing up, you agree to the <termsUrl>terms of use</termsUrl> & <privacyUrl>privacy policy</privacyUrl>.',
    values: {
      termsUrl: (chunks) => <a href={`${baseUrl}/terms`}>{chunks}</a>,
      privacyUrl: (chunks) => <a href={`${baseUrl}/privacy`}>{chunks}</a>
    }
  }
});

@injectStore
@injectIntl
@observer
export class SignupSms extends React.Component<any> {
  state: { token: string; submitWasSuccessful: undefined | boolean } = {
    token: '',
    submitWasSuccessful: undefined
  };
  componentDidMount() {
    this.props.store.user.trackAppEventViaApi('signup-view');
    const token = getURLQueryParam('token');
    const sessionId = getURLQueryParam('sessionId');
    token && sessionId ? this.setState({ token }) : this.props.store.nav('signup');
  }

  async handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const result: boolean = await this.props.store.user.smsSignup(this.state.token);
    this.setState({ submitWasSuccessful: result });
  }

  handleNav(name: string, e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    this.props.store.nav(name);
  }

  render() {
    const store = this.props.store;
    const user = store.user;
    const data = user.signupData;
    const f = this.props.intl.formatMessage;

    return (
      <div className="signup">
        <Helmet>
          <title itemProp="name">{f(messages.title)}</title>
          <meta name="description" content={f(messages.description)} />
          <body className="app-theme app-theme-focus" />
        </Helmet>

        <div className="focus-container">
          <div className="focus-header">
            <a className="brand" href="/" onClick={(e) => this.handleNav('home', e)}>
              <img
                className="brand-logo"
                src="/images/logo.png"
                role="presentation"
                alt="invoice simple logo"
                data-pin-nopin="true"
              />
            </a>
            <h1>
              <FormattedMessage {...messages.header} />
            </h1>
            <h2>
              <FormattedMessage {...messages.subHeader} />
            </h2>
          </div>
          <div className="focus-body">
            {SubscriptionTrackerStore.isVerifying ? (
              <div className="alert-loading d-flex justify-content-center pb-4">
                <FontAwesomeIcon icon="spinner" size="lg" color="black" spin />
              </div>
            ) : (
              <Alert />
            )}
            <form acceptCharset="UTF-8" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="form-group">
                <label htmlFor="firstName">
                  <FormattedMessage {...messages.formLabelFirstName} />
                </label>
                <input
                  id="firstName"
                  type="text"
                  placeholder={f(messages.formPlaceholderFirstName)}
                  disabled={user.isSigningUp}
                  value={data.firstName}
                  onChange={(e) => (data.firstName = e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">
                  <FormattedMessage {...messages.formLabelLastName} />
                </label>
                <input
                  id="lastName"
                  type="text"
                  placeholder={f(messages.formPlaceholderLastName)}
                  disabled={user.isSigningUp}
                  value={data.lastName}
                  onChange={(e) => (data.lastName = e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  <FormattedMessage {...messages.formLabelEmail} />
                </label>
                <EmailInput
                  key="user-signup"
                  name="email"
                  placeholder={
                    SubscriptionTrackerStore.isVerifying
                      ? f(messages.formButtonLabelLoading)
                      : f(messages.formPlaceholderEmail)
                  }
                  disabled={SubscriptionTrackerStore.isVerifying || user.isSigningUp}
                  value={data.email}
                  onChange={(e) => (data.email = e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">
                  <FormattedMessage {...messages.formLabelPassword} />
                </label>
                <input
                  id="password"
                  type="password"
                  placeholder="******"
                  disabled={user.isSigningUp}
                  value={data.password}
                  onChange={(e) => (data.password = e.target.value)}
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block btn-prime"
                  disabled={this.state.submitWasSuccessful || user.isSigningUp}>
                  {user.isSigningUp ? (
                    <FormattedMessage {...messages.formButtonLabelPending} />
                  ) : SubscriptionTrackerStore.isVerifying ? (
                    <FormattedMessage {...messages.formButtonLabelLoading} />
                  ) : (
                    <FormattedMessage {...messages.formButtonLabel} />
                  )}
                </button>
                <a className="btn btn-cancel" onClick={(e) => this.handleNav('home', e)}>
                  <FormattedMessage {...messages.cancelButtonLabel} />
                </a>
                <div className="signup-terms">
                  <FormattedMessage {...messages.signupTermsAgreement} />
                </div>
              </div>
            </form>
          </div>
          <div className="focus-footer">
            <FormattedMessage {...messages.loginLinkQuestion} />
            <a
              href="/login"
              className="btn"
              onClick={(e) => this.handleNav('login', e)}
              style={{ marginLeft: 10 }}>
              <FormattedMessage {...messages.loginLinkLabel} />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
