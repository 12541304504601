import axios from 'axios';
import flagsmith from 'flagsmith';
import { computed } from 'mobx';
import { v1 as uuid } from 'uuid';

import { Platform } from '@invoice-simple/common';
import { GenericEventMeta, WebEventName } from '@invoice-simple/is-events';

import * as sessionReplay from '@amplitude/session-replay-browser';

import { getAmplitudeSessionId } from 'src/components/Amplitude/hooks/use-amplitude';
import { IsIdentifyPayload } from 'src/types/IsEvents';
import { getVWOExp } from 'src/util/vwo';
import Rollbar from '../analytics/rollbar';
import environmentStore from '../stores/EnvironmentStore';
import UserModel from './UserModel';

interface ClickEvent {
  text: string;
  action: string;
  path?: string;
  [key: string]: unknown;
}

interface ModalEvent {
  action: string;
  [key: string]: unknown;
}

export default class UserEventModel {
  user: UserModel;

  constructor(user: UserModel) {
    this.user = user;
  }

  @computed
  get defaultEventData(): any {
    return {
      abTests: {
        ...this.user.abTests,
        ...flagsmith.getAllFlags()
      },
      app: environmentStore.appHost,
      path: window.location.pathname,
      url: window.location.href,
      platform: Platform.WEB,
      newSession: this.user.isSessionNew,
      requestId: this.user.requestId,
      country: this.user.countryCode || null,
      connectionType: environmentStore.connectionType
    };
  }

  public forAmplitude(): IsIdentifyPayload {
    const vwoExp = getVWOExp();
    return {
      userId: this.user.id,
      accountId: this.user.accountId,
      abTests: {
        ...this.user.abTests,
        ...flagsmith.getAllFlags()
      },
      extraUserProperties: {
        createdAt: this.user.accountCreationTimestamp,
        isSubscriptionActive: this.user.isSubActive,
        hasFreeTrialSubscription: this.user.hasFreeTrialSubscription,
        subscriptionTier: this.user.subscriptionTier || '',
        subscriptionOrderSku: this.user.subscriptionOrderSku || '',
        ...vwoExp
      }
    };
  }

  private fireEventRequest<T extends WebEventName>(name: T, data?: GenericEventMeta<T>) {
    const sessionReplayProperties = sessionReplay.getSessionReplayProperties();
    const meta = Object.assign({}, this.defaultEventData, data, sessionReplayProperties);

    const eventData = {
      id: uuid(),
      name,
      meta,
      user: this.user.id,
      account: this.user.accountId,
      installation: environmentStore.installationId,
      app: environmentStore.appHost,
      app_version: environmentStore.getReactAppVersion(),
      timestamp: new Date().valueOf(),
      session_id: getAmplitudeSessionId()
    };

    return axios.post('/api/v2/event-all', eventData, this.user.getApiReqOpts()).catch((error) => {
      Rollbar.trackError('trackAppEventViaApi-error', error, {
        data: {
          event: {
            name
          }
        }
      });
    });
  }

  /**
   * @deprecated Use trackAction or generic UX track methods instead.
   */
  public trackEvent<T extends WebEventName>(name: T, data?: GenericEventMeta<T>) {
    return this.fireEventRequest(name, data);
  }

  public async trackAction<T extends WebEventName>(name: T, data?: GenericEventMeta<T>) {
    try {
      return this.fireEventRequest(name, data);
    } catch (error) {
      Rollbar.trackError('trackAction-error', error, {
        data: {
          event: {
            name
          }
        }
      });
    }
  }

  public trackPageView = (path: string) => {
    let eventBody = { path };
    const vwoExp = getVWOExp();
    if (vwoExp) {
      eventBody = { ...eventBody, ...vwoExp };
    }
    return this.fireEventRequest('web-page-view', eventBody);
  };

  public trackModalView = (eventData: ModalEvent) => {
    return this.fireEventRequest('web-modal-view', eventData);
  };

  public trackButtonClick = (eventData: ClickEvent) => {
    return this.fireEventRequest('web-button-click', eventData);
  };

  public trackLinkClick = (eventData: ClickEvent) => {
    return this.fireEventRequest('web-link-click', eventData);
  };

  public trackNavClick = (eventData: ClickEvent) => {
    return this.fireEventRequest('web-nav-click', eventData);
  };

  public trackTabClick = (eventData: ClickEvent) => {
    return this.fireEventRequest('web-tab-click', eventData);
  };
}
