import React from 'react';

import { inject, observer } from 'mobx-react';

import { defineMessages, injectIntl } from 'src/i18n';
import { ISIntl } from 'src/i18n/utils';
import { AppStore } from 'src/stores/AppStore';
import { compose } from 'src/util/functions';
import NavItem from './NavItem';
import { NavMenu } from './NavMenu';
import NavMenuItem from './NavMenuItem';
import PaymentsNavItem from './PaymentsNavItem';
import { changeLocationTo } from './utils';

const messages = defineMessages({
  invoices: {
    id: 'navbar.navItems.invoices',
    defaultMessage: 'invoices'
  },
  estimates: {
    id: 'navbar.navItems.estimates',
    defaultMessage: 'estimates'
  },
  expenses: {
    id: 'navbar.navItems.expenses',
    defaultMessage: 'expenses'
  },
  clients: {
    id: 'navbar.navItems.clients',
    defaultMessage: 'clients'
  },
  items: {
    id: 'navbar.navItems.items',
    defaultMessage: 'items'
  },
  reports: {
    id: 'navbar.navItems.reports',
    defaultMessage: 'reports'
  }
});

const NavItems = (props: { store: AppStore; intl: ISIntl }) => {
  const { store } = props;
  const { location } = store;
  const isDisabled = store.isLoading;
  const { fta } = props.intl;

  return (
    <nav className="nav-items">
      <NavItem
        href="/invoices"
        isActive={location.isInvoice}
        isDisabled={isDisabled}
        onClick={(e) => {
          if (process.env.REACT_APP_DOC_LIST_NEXTJS_MIGRATION_ENABLED === 'true') {
            window.location.assign(`/invoices`);
            return;
          }

          return changeLocationTo(store.nav, 'invoiceList')(e);
        }}>
        {fta(messages.invoices)}
      </NavItem>

      <NavItem
        href="/estimates"
        isActive={location.isEstimate}
        isDisabled={isDisabled}
        onClick={(e) => {
          if (process.env.REACT_APP_DOC_LIST_NEXTJS_MIGRATION_ENABLED === 'true') {
            window.location.assign(`/estimates`);
            return;
          }

          return changeLocationTo(store.nav, 'estimateList')(e);
        }}>
        {fta(messages.estimates)}
      </NavItem>

      <a rel="nofollow" className={'nav-item'} href={`${process.env.REACT_APP_URL}/expenses`}>
        {fta(messages.expenses)}
      </a>

      <PaymentsNavItem isActive={location.isPaymentsDashboard} isDisabled={isDisabled} />

      <NavItem
        href="/reports"
        isActive={location.isReport}
        isDisabled={isDisabled}
        onClick={changeLocationTo(store.nav, 'reportList')}>
        {fta(messages.reports)}
      </NavItem>

      <NavMenu title={'More'} customClasses="nav-menu">
        <NavMenuItem
          isActive={location.isClient}
          href="/clients"
          onClick={changeLocationTo(store.nav, 'clientList')}>
          {fta(messages.clients)}
        </NavMenuItem>
        <a rel="nofollow" href={`${process.env.REACT_APP_URL}/items`}>
          {fta(messages.items)}
        </a>
      </NavMenu>
    </nav>
  );
};

export default compose(inject('store'), injectIntl, observer)(NavItems);
