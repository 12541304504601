import React from 'react';

import { FormattedMessage } from 'react-intl';

import { InvoiceCreationLimit } from '@invoice-simple/feature-gate';
import { Platform } from '@invoice-simple/is-events';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ISIntl } from 'src/i18n';
import LocationModel from 'src/models/LocationModel';
import UserModel from 'src/models/UserModel';
import { LocalStorageKey } from 'src/types/LocalStorageKey';
import { URLQueryParamKeys } from 'src/util/url';
import { BannerType } from '../Banner';
import { messages } from './messages';

export type DocLimitBannerConfig = {
  type: BannerType;
  icon?: React.ReactNode;
  title?: string;
  body: React.ReactNode;
  isClosable: boolean;
};

export const getBannerConfig = (remainingDocCount: number, intl: ISIntl): DocLimitBannerConfig => {
  const hasRemainingDocs = remainingDocCount > 0;
  const type = hasRemainingDocs ? 'warning' : 'danger';
  const icon = hasRemainingDocs ? undefined : (
    <FontAwesomeIcon icon="exclamation-circle" size="lg" className="mr-3" />
  );
  const title = getFreeBannerTitle(remainingDocCount, intl);
  const body = getFreeBannerBody(remainingDocCount, intl);

  return {
    type,
    icon,
    title,
    body,
    isClosable: false
  };
};

export const trackDocLimitBannerEvent = async (action: 'show' | 'cta-click' | 'dismiss') => {
  const user = UserModel.getInstance();

  user.events.trackAction('doc-limit-banner', {
    action,
    'docs-remaining': user.docsRemaining,
    platform: Platform.web,
    source: getBannerSource()
  });
};

export const trackDocLimitBannerShown = async () => {
  const user = UserModel.getInstance();

  const docLimitBannerCount = parseInt(
    localStorage.getItem(LocalStorageKey.DOC_LIMIT_BANNER_COUNT) || '0'
  );

  if (docLimitBannerCount === user.docsRemaining) {
    return;
  }

  trackDocLimitBannerEvent('show');
  localStorage.setItem(LocalStorageKey.DOC_LIMIT_BANNER_COUNT, `${user.docsRemaining}`);
};

const getBannerSource = (): string => {
  switch (true) {
    case LocationModel.isInvoicesList:
      return 'invoices';
    case LocationModel.isSetting:
      return 'settings';
    default:
      return LocationModel.name;
  }
};

const getFreeBannerTitle = (remainingDocCount: number, intl: ISIntl): string | undefined => {
  const showInitialBanner = remainingDocCount >= InvoiceCreationLimit.FREE_LIMIT_LOGGED_IN;

  if (showInitialBanner) {
    return;
  }

  return remainingDocCount === 1
    ? intl.ft(messages.free.titleSingular)
    : intl.ft(messages.free.titlePlural, { count: remainingDocCount });
};

const getFreeBannerBody = (remainingDocCount: number, intl: ISIntl): React.ReactNode => {
  const showInitialBanner = remainingDocCount >= InvoiceCreationLimit.FREE_LIMIT_LOGGED_IN;

  const onCtaClick = (e: React.MouseEvent) => {
    e.preventDefault();
    trackDocLimitBannerEvent('cta-click');
    LocationModel.navAndScrollTop('subscription', {
      [URLQueryParamKeys.REF]: URLQueryParamKeys.DOC_LIMIT_BANNER
    });
  };

  const href = `/subscription?${URLQueryParamKeys.REF}=${URLQueryParamKeys.DOC_LIMIT_BANNER}`;
  const getUpgradeElement = (chunks: React.ReactNode) => (
    <a href={href} className="font-bold underline" onClick={onCtaClick}>
      {chunks}
    </a>
  );

  if (showInitialBanner) {
    return (
      <>
        {intl.ft(messages.free.initialBody1, {
          docCount: InvoiceCreationLimit.FREE_LIMIT_LOGGED_IN
        })}
        <span className="hidden sm:inline-block">&nbsp;</span>
        <span className="block sm:inline-block">
          <FormattedMessage
            id="docLimit.banner.free.initial.body2"
            values={{ upgrade: getUpgradeElement }}
          />
        </span>
      </>
    );
  }

  return (
    <FormattedMessage id="docLimit.banner.free.body" values={{ upgrade: getUpgradeElement }} />
  );
};
