import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DocListModel from 'src/models/DocListModel';
import {
  PAYMENT_MODAL_DELAYED_TIME,
  PaymentEventLocation,
  PaymentEventName,
  PaymentModalTriggerName,
  PaymentPromoModal,
  withPaymentPromoModalHook
} from 'src/payments';
import environmentStore from 'src/stores/EnvironmentStore';
import { compose } from 'src/util/functions';
import { Documents } from './Documents';
import { usePaginatedDocuments } from './hooks/usePaginatedDocuments';

import './DocList.scss';

import classNames from 'classnames';

import { trackEvent } from 'src/analytics/controller';
import { useISIntl } from 'src/i18n/utils';
import AlertModel from 'src/models/AlertModel';
import { docTypeToName } from 'src/util/docType';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import { useDebounce } from 'src/util/useDebounce';
import { DocLimitBanner } from '../DocLimitBanner';
import { PaginationButtons } from '../PaginationButtons';
import { FriendbuyShareModal } from '../Referral/FriendbuyShareModal';
import {
  shouldShowReferralModal,
  shouldShowReferralPurchaseModal
} from '../Referral/referral.util';
import ReferralModal from '../Referral/ReferralModal';
import ReferralPurchaseModal from '../Referral/ReferralPurchaseModal';
import { useSearchDocuments } from './hooks/useSearchDocuments';
import { messages } from './messages';

interface DocListProps {
  list: DocListModel;
  onView: (id?: string) => void;
  onCreate: () => void;
  onLoad: () => void;
  onError: (error: { title?: string; body?: string }) => void;
  shouldShowPaymentPromoModal: boolean;
  showPaymentPromoModal: () => void;
  isEligibleForPaymentPromoModal: () => boolean;
}

const DocList = (props: DocListProps) => {
  const { formatMessage: f, fta, ft, getLocalizedPluralDocumentType } = useISIntl();
  const [query, setQuery] = useState('');

  const { list } = props;

  const {
    data: paginationData,
    isInitialLoading: isPaginationLoading,
    isError: isPaginationError
  } = usePaginatedDocuments({
    dir: list.orderByDir,
    docType: list.docType,
    limit: list.limit,
    state: list.state,
    url: list.url
  });

  const {
    data: searchData,
    isInitialLoading: isSearchLoading,
    isError: isSearchError
  } = useSearchDocuments(
    { searchTerm: useDebounce(query, 500), docType: list.docType },
    {
      onSuccess: () => {
        props.list.user.trackAppEventViaApi('web-search', {
          page: list.isInvoice ? 'invoices' : 'estimates'
        });
      }
    }
  );

  const data = searchData || paginationData;
  const isLoading = isSearchLoading || isPaginationLoading;
  const isError = isSearchError || isPaginationError;

  const hasSearchResults = !!searchData?.documents.length;

  useEffect(() => {
    props.onLoad();
    const action = getURLQueryParam(URLQueryParamKeys.ACTION);
    if (action === URLQueryParamKeys.INVOICE_SEND_ACTION) {
      handleInvoiceEmailSendEffects();
    }

    const isPostSubTransfer = getURLQueryParam(URLQueryParamKeys.SUB_TRANSFER);
    switch (isPostSubTransfer) {
      case 'true':
        AlertModel.setAlert('success', f(messages.purchaseGratitude), f(messages.support));
        break;
      case 'false':
        AlertModel.setAlert(
          'danger',
          f(messages.transferErrorTitle),
          f(messages.transferErrorBody)
        );
        break;
    }
  }, []);

  const handleInvoiceEmailSendEffects = () => {
    const invoiceNo = getURLQueryParam(URLQueryParamKeys.INVOICE_NO);
    const toEmail = getURLQueryParam(URLQueryParamKeys.TO_EMAIL);
    const docType = getURLQueryParam(URLQueryParamKeys.EMAIL_DOCTYPE);

    if (invoiceNo && toEmail && docType) {
      AlertModel.setAlert(
        'success',
        f(
          { id: 'invoice.email.alert.success.title' },
          { docType, invoiceNo, userEmail: [toEmail].join(', ') }
        )
      );
    }
    setTimeout(() => {
      if (props.isEligibleForPaymentPromoModal()) {
        props.showPaymentPromoModal();
        props.list.user.trackAppEventViaApi(PaymentEventName.paymentModalViewed, {
          location: PaymentEventLocation.invoiceEditor,
          trigger: PaymentModalTriggerName.invoiceEmailSend
        });
      }
    }, PAYMENT_MODAL_DELAYED_TIME);
  };

  const isSearching = query !== '';

  function handleOrder() {
    if (isSearching) {
      return;
    }
    props.list.setOrderByCol();
  }

  function handleFilter(name?: 'open' | 'paid') {
    props.list.setUrl('');
    props.list.setFilterBy(name);
  }

  function handleSearch(searchTerm: string) {
    setQuery(searchTerm);
  }

  const compClass = 'invoice-list';
  const tableHeadTotal =
    !list.isPaid && list.isInvoice ? ft(messages.tableHeadBalance) : ft(messages.tableHeadTotal);

  return (
    <div className={compClass}>
      {props.shouldShowPaymentPromoModal && (
        <PaymentPromoModal
          onTrackEventAndLocation={(eventName, paymentEventLocation) => {
            list.user.trackAppEventViaApi(eventName, {
              location: paymentEventLocation
            });
          }}
        />
      )}
      {shouldShowReferralModal() && <ReferralModal />}
      {shouldShowReferralPurchaseModal() && <ReferralPurchaseModal />}
      <FriendbuyShareModal />

      <div className="container">
        {!environmentStore.isSnapshot() && (
          <Helmet>
            <title itemProp="name">{ft(messages.title)}</title>
            <meta name="description" content={f(messages.description)} />
          </Helmet>
        )}

        {list.isInvoice && <DocLimitBanner />}

        <div
          className={classNames([
            'page-header doc-search-container',
            { 'invoice-list-header': list.isInvoice },
            { 'estimate-list-header': list.isEstimate }
          ])}>
          <div className="btn-group btn-group-sm btn-group-filter" role="group">
            <button
              type="button"
              disabled={isSearching}
              data-selector="all-documents-filter-button"
              className={`btn btn-filter${!list.state && !isSearching ? ' active' : ''}`}
              onClick={() => handleFilter()}>
              {fta(messages.filterAll)}
            </button>
            <button
              type="button"
              disabled={isSearching}
              data-selector="unpaid-documents-filter-button"
              className={`btn btn-filter${list.isOpen && !isSearching ? ' active' : ''}`}
              onClick={() => handleFilter('open')}>
              {list.isEstimate ? 'Open' : fta(messages.filterUnpaid)}
            </button>
            <button
              type="button"
              disabled={isSearching}
              data-selector="paid-documents-filter-button"
              className={`btn btn-filter${list.isPaid && !isSearching ? ' active' : ''}`}
              onClick={() => handleFilter('paid')}>
              {list.isEstimate ? 'Closed' : fta(messages.filterPaid)}
            </button>
          </div>
          <div className="doc-list-actions">
            <input
              type="text"
              id="doc-search"
              placeholder={f(messages.search)}
              onChange={(e) => handleSearch(e.target.value)}
              value={query}
              className="mr-2"
            />
            <a
              className={`btn btn-prime btn-${docTypeToName(list.docType)}-new`}
              onClick={() => {
                list.setUrl('');
                props.onCreate();
              }}>
              <span>{fta(messages.buttonNew)}</span>
            </a>
          </div>
        </div>

        <div className="invoice-list-body panel">
          <table className="table">
            <thead className="thead">
              <tr>
                <th className="invoice-head-invoice">
                  <SortToggler
                    list={list}
                    label={`${fta(messages.sortByInvoice)} `}
                    field="number"
                  />
                </th>
                <th className="invoice-head-client">{fta(messages.tableHeadClient)}</th>
                <th
                  className="invoice-head-data d-none d-sm-table-cell"
                  onClick={() => handleOrder()}>
                  <SortToggler
                    disabled={isSearching}
                    list={list}
                    label={`${fta(messages.tableHeadDate)} `}
                    field="date"
                  />
                </th>

                <th className="invoice-head-state">{/* Status */}</th>
                <th className="invoice-head-total">
                  <SortToggler list={list} label={`${tableHeadTotal} `} field="total" />
                </th>
                <th className="invoice-head-actions" style={{ textAlign: 'right' }}>
                  &nbsp;
                </th>
              </tr>
            </thead>

            {isLoading ? (
              <tbody>
                <tr className="invoice-row-loading">
                  <td colSpan={5}>
                    <span>
                      {f(messages.rowLoading, {
                        docType: getLocalizedPluralDocumentType(list.docType)
                      })}
                    </span>
                  </td>
                </tr>
              </tbody>
            ) : (
              <Documents
                isPaid={list.isPaid}
                onView={props.onView}
                onCreate={props.onCreate}
                data={data}
                isError={isError}
                query={query}
                hasSearchResults={hasSearchResults}
                onError={props.onError}
                docType={list.docType}
                state={list.state}
              />
            )}
          </table>
        </div>

        <div className="invoice-list-footer">
          <span>{f(messages.tableFooterHint)}</span>
        </div>

        <PaginationButtons
          data={paginationData}
          hidden={isSearching}
          onPrev={() => {
            trackEvent('pagination-click', {
              path: window.location.pathname,
              action: 'prev',
              page:
                paginationData?.prev && paginationData.next
                  ? 'other'
                  : paginationData?.next
                    ? 'first'
                    : 'last'
            });
            list.setUrl(paginationData!.prev);
          }}
          onNext={() => {
            trackEvent('pagination-click', {
              path: window.location.pathname,
              action: 'next',
              page:
                paginationData?.prev && paginationData?.next
                  ? 'other'
                  : paginationData?.next
                    ? 'first'
                    : 'last'
            });
            list.setUrl(paginationData!.next);
          }}
        />
      </div>
    </div>
  );
};

export default compose(withPaymentPromoModalHook, observer)(DocList);

const SortToggler = observer((props) => (
  <>
    {props.label}
    {!props.disabled && (
      <>
        {props.list.isOrderByWithDir(props.field, 'asc') && (
          <FontAwesomeIcon icon="chevron-up" size="xs" />
        )}
        {props.list.isOrderByWithDir(props.field, 'desc') && (
          <FontAwesomeIcon icon="chevron-down" size="xs" />
        )}
      </>
    )}
  </>
));
