export const getVWOExp = () => {
  if (typeof window !== 'undefined' && window?._vwo_exp) {
    // loop through all exp and find one where status is "RUNNING"
    const activeExp: { [key: string]: string } = {};
    Object.entries(window._vwo_exp).forEach(([expId, exp]) => {
      const variationId = exp?.combination_chosen;
      const isType = ['VISUAL_AB', 'VISUAL', 'SPLIT_URL'].includes(exp.type);

      if (variationId && isType) {
        const variationName = exp.comb_n[variationId];
        if (variationName) {
          activeExp[`vwo-test-${expId}`] = variationName;
        }
      }
    });
    return activeExp;
  }
  return {};
};
