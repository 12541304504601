import axios, { AxiosError } from 'axios';
import flagsmith from 'flagsmith';
import { DebouncedFunc } from 'lodash';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import { action, computed, observable } from 'mobx';
import Parse from 'parse';

import {
  ConsentStatus,
  ConsentType,
  DocType,
  DocTypes,
  Platform,
  SettingKeys,
  SubscriptionTier
} from '@invoice-simple/common';
import {
  ExportLimit,
  FeatureName,
  hasFullAccessToFeature,
  InvoiceCreationLimit
} from '@invoice-simple/feature-gate';
import { getCouponDiscount, PaywallCouponConfig } from '@invoice-simple/is-coupon';
import { GenericEventMeta, WebEventName } from '@invoice-simple/is-events';

import { updateAnalyticsProviders } from 'src/analytics/controller';
import { isConsentGivenOrImplicit, saveConsentData } from 'src/apis/consentAPI';
import { getCouponInfo } from 'src/apis/couponAPI';
import { getSentDocuments } from 'src/apis/msgAPI';
import { getSubscriptionStatusV3, getSubscriptionsV3 } from 'src/apis/subscriptionsAPI';
import { parseUserFetchData } from 'src/apis/userAPI';
import { isReferralShareRef } from 'src/components/AdvocateConsentModal/utils';
import { getAdvocateReferralCode } from 'src/components/Referral/referral.util';
import {
  getPaywallCouponInfo,
  isFreeTierPaywallEnabled,
  showPremiumOnlyPaywall
} from 'src/components/SubscriptionPaywall/utils';
import { defaultsStore } from 'src/stores/DefaultsStore';
import { ApiCouponError } from 'src/types/ApiCoupon';
import { InvoiceExportAction } from 'src/types/Invoice';
import { PaywallType } from 'src/types/Paywall';
import { updateBrazeCustomAttributes, UserAttributes } from 'src/util/braze';
import { getCookie, setCookie } from 'src/util/cookie';
import { flattenExperiments } from 'src/util/experiments';
import { getValueOrDefault } from 'src/util/getValueOrDefault';
import { shouldOfferLocalizedPrices } from 'src/util/subscriptions';
import { getTierSku } from 'src/util/subscriptionSku';
import { getURLQueryParam, URLQueryParamKeys } from 'src/util/url';
import Rollbar from '../analytics/rollbar';
import abTestOverrides from '../data/abTestOverrides';
import countrySettingOptions from '../data/countrySettingOptions';
import environmentStore from '../stores/EnvironmentStore';
import { ApiRequestOptions } from '../types/ApiRequestOptions';
import { ApiSubscription } from '../types/ApiSubscription';
import { ApiSubscriptionUpgrade } from '../types/ApiSubscriptionUpgrade';
import { CountrySettings } from '../types/CountrySettings';
import { ServerData, UserMeta } from '../types/ServerData';
import { removeUndefinedOrNull } from '../util/functions';
import { Account } from '../util/IsParseDomain';
import ClientListModel from './ClientListModel';
import ClientSuggestModel from './ClientSuggestModel';
import DocListModel from './DocListModel';
import errorListModel from './ErrorListModel';
import ItemListModel from './ItemListModel';
import ItemSuggestModel from './ItemSuggestModel';
import location from './LocationModel';
import { PaymentsOrderListModel } from './PaymentsOrderListModel';
import ReportModel from './ReportModel';
import SettingListModel from './SettingListModel';
import SubscriptionModel, { PAYMENTS_TIER_SKU_REGEX } from './SubscriptionModel';
import SubscriptionUpgradeModel from './SubscriptionUpgradeModel';
import * as UserAuth from './UserAuth';
import UserEventModel from './UserEventModel';

let userInstance: UserModel;
const surchargeConsentVersion = 'v0';

export enum Cadence {
  ANNUAL = 'annual',
  MONTHLY = 'monthly'
}

type GetTierPriceParams = {
  tier: SubscriptionTier;
  cadence: Cadence;
  countrySettings: CountrySettings;
};

type GetDiscountTierPriceParams = {
  tier: SubscriptionTier;
  cadence: Cadence;
  paywallCouponConfig?: PaywallCouponConfig;
  usdPrice?: boolean;
};

export default class UserModel {
  static getInstance() {
    if (userInstance) {
      return userInstance;
    }
    userInstance = new UserModel(true);
    return userInstance;
  }

  get = getValueOrDefault(this.getDefault);

  initializedFromLocalStorage: boolean = false;
  abTests = defaultsStore.abTests;
  debounceMobileSync: DebouncedFunc<() => void>;

  @observable
  meta: UserMeta = {
    hasProvidedRating: false
  };

  @observable initPromise: Promise<void>;

  //  temp object to manage/validate sessiond ata
  @observable passwordResetData: any = { email: '' };
  @observable loginData: any = { email: '', password: '' };
  @observable signupData: any = { firstName: '', lastName: '', email: '', password: '' };

  @observable serverData: ServerData;

  @observable mobileSyncCount: number = 0;
  @observable isMobileSyncing: boolean = false;

  // state
  @observable loadPromise: Promise<[void, void, void, void]>;
  @observable isLoaded: boolean = false;
  @observable isSaving: boolean = false;

  @observable isLoggingOut: boolean = false;
  @observable isLoggingIn: boolean = false;
  @observable isSigningUp: boolean = false;

  @observable isSessionNew: boolean = true;
  @observable hasWelcomeAlertCookie: boolean = true;

  @observable invoicesCompleted: number = 0;

  @observable id?: string;
  @observable accountId: string;
  @observable requestId: string;
  @observable sessionToken: string;
  @observable username: string;
  @observable email: string | undefined;
  @observable password: string;
  @observable countryCode: string | undefined;
  @observable firstName: string;
  @observable lastName: string;
  @observable createdAt: Date;
  @observable updatedAt: Date;

  @observable docsCount: number = 0; // Invoices only
  @observable allDocsCount: number = 0; // Invoices + Estimates
  @observable docsCountLastMonth: number = 0; // Invoices only

  // unfortunately, order matters, please keep settings list first
  // subs fix = restore these as @observable
  @observable settingList = new SettingListModel(this);
  @observable clientList = new ClientListModel(this);
  @observable clientSuggest = new ClientSuggestModel(this);
  @observable itemSuggest = new ItemSuggestModel(this);
  @observable itemList = new ItemListModel(this);
  @observable invoiceList = new DocListModel(this, 0);
  @observable estimateList = new DocListModel(this, 1);
  @observable reportList = new ReportModel(this);
  @observable paymentsOrderListModel = new PaymentsOrderListModel();
  @observable subs: SubscriptionModel[] = [];
  @observable accountCreationTimestamp: number | null = null;
  @observable hadPaidSubsInThePast: boolean = false;

  @observable isSubActive: boolean = false;
  @observable onboardingCompleted: boolean = false;
  @observable hasFreeTrialSubscription: boolean = false;
  @observable subscriptionTier: SubscriptionTier | null = null;
  @observable subscriptionOrderSku: string | null = null;
  @observable paywallCouponInfo?: PaywallCouponConfig;
  @observable paywallCouponStatus?: 'expired' | 'invalid' | 'not_eligible' | 'valid';
  @observable advocateReferralCode: string | null = null;
  @observable accountSource?: string = defaultsStore.accountSource;

  isSurchargeConsentGiven: boolean = false;
  events = new UserEventModel(this);

  private countryCodeFromLocale(): string {
    return defaultsStore?.locale?.name?.split('-')[1];
  }

  public get countrySettings(): CountrySettings {
    const countryCode = this.activeCountryCode();
    const defaultCountrySettings = countrySettingOptions.US;

    return countrySettingOptions[countryCode] || defaultCountrySettings;
  }

  private activeCountryCode(): string {
    return this.countryCode || this.countryCodeFromLocale();
  }

  public get isFromEnglishSpeakingCountry(): boolean {
    const countryCode = this.countryCodeFromLocale();

    return ['US', 'AU', 'GB', 'CA'].includes(countryCode);
  }

  public get geoSubCurrencyCode(): string {
    return this.countrySettings.subscription.currencyCode;
  }

  constructor(safe?: boolean, isTest?: boolean) {
    if (!safe) {
      throw new Error(
        'There should be only single instance of user model in the app, are you sure you want to create a new instead of importing an existing instance?'
      );
    }

    if (isTest) {
      return;
    }

    this.initPromise = UserAuth.init(this);
    this.debounceMobileSync = debounce(this.mobileSync, 9000);
    this.hasWelcomeAlertCookie = !!getCookie('isWelcomeV1');
    this.invoicesCompleted = getCookie('invoicesCompleted') || 0;
  }

  @computed
  public get lastPaidSub(): SubscriptionModel | null {
    const sortedPaidSubs = sortBy(this.paidSubs, (s) => {
      return s.orderTimestamp;
    });

    return (sortedPaidSubs.length && sortedPaidSubs[sortedPaidSubs.length - 1]) || null;
  }

  @computed
  public get lastPaidWebSub(): SubscriptionModel | null {
    const webSubs = filter(
      this.paidSubs,
      (s) => s.orderSku && s.orderSku.match(/web/)
    ) as SubscriptionModel[];

    const sortedPaidSubs = sortBy(webSubs, (s) => {
      return s.orderTimestamp;
    });

    return (sortedPaidSubs.length && sortedPaidSubs[sortedPaidSubs.length - 1]) || null;
  }

  @computed
  public get lastActiveSub(): SubscriptionModel | null {
    if (this.isSubPaid) {
      return last(sortBy(this.activePaidSubs, (s) => s.orderTimestamp)) || null;
    }
    return last(sortBy(this.activeTrialSubs, (s) => s.orderTimestamp)) || null;
  }

  @computed
  public get hasAnnualSub(): boolean {
    return this.activePaidSubs.some((sub) => sub.isAnnual);
  }

  public lastActivePaidWebSub(tier?: SubscriptionTier | null): SubscriptionModel | null {
    const activePaidWebTieredSubs = filter(this.activePaidSubs, (s) => {
      if (tier === SubscriptionTier.PREMIUM) {
        return s.isPremiumTier;
      }

      if (tier === SubscriptionTier.PAYMENTS_TIER) {
        return PAYMENTS_TIER_SKU_REGEX.test(s.orderSku);
      }

      return s.orderSku && tier
        ? new RegExp(`web\\S*${tier}.tier`, 'i').test(s.orderSku)
        : /web/i.test(s.orderSku);
    });

    return last(sortBy(activePaidWebTieredSubs, (s) => s.orderTimestamp)) ?? null;
  }

  public lastActivePaidMobileSub(): SubscriptionModel | null {
    const activePaidMobileSubs = filter(this.activePaidSubs, (s) => {
      return s.isMobileOnly;
    });

    return last(sortBy(activePaidMobileSubs, (s) => s.orderTimestamp)) ?? null;
  }

  @computed
  public get isLegacySubscriber(): boolean {
    const lastPaidSub = this.lastPaidSub;
    return !!(lastPaidSub && lastPaidSub.orderSku && lastPaidSub.orderSku.match(/web.monthly1/));
  }

  @computed
  public get isInternalTester(): boolean {
    return !!this.email?.includes('@invoicesimple.com') || process.env.NODE_ENV === 'development';
  }

  @computed
  public get isSignedUp(): boolean {
    return !!this.username?.startsWith('new_');
  }

  @computed
  public get geoSubAmount(): number {
    if (this.isSubTrial) {
      return this.subUpgrade.newPrice;
    }
    return this.countrySettings.subscription.price[0];
  }

  @computed
  public get geoSubOrderSku(): string {
    if (this.isSubTrial) {
      return this.subUpgrade.orderSku;
    }
    return `com.invoicesimple.web.monthly.3.${this.geoSubCurrencyCode.toLowerCase()}`;
  }

  @computed
  public get shouldOfferCouponSku() {
    /**
     * Temporarily disable coupons:
     * https://app.asana.com/0/1200380663730997/1202439586225845/f
     */
    return false;
  }

  @action
  public getCouponSku(cadence: Cadence = Cadence.MONTHLY) {
    if (this.shouldOfferCouponSku) {
      // coupon sku format - coupon.invoicesimple.web.monthly.1.usd ;
      return `coupon.invoicesimple.web.${cadence}.1.${this.geoSubCurrencyCode.toLowerCase()}`;
    }

    return;
  }

  @computed
  public get shouldOfferPaywallDiscount() {
    const getActiveCoupon = getPaywallCouponInfo();

    return !!getActiveCoupon;
  }

  private getTierPrice({ tier, cadence, countrySettings }: GetTierPriceParams): number {
    const tierPrices = countrySettings.subscription['tierPrice'];

    let tierKey: string = tier;
    switch (tier) {
      case SubscriptionTier.PREMIUM:
        const newPremium = 'newPremium';
        if (this.shouldOfferNewTieredPricing && tierPrices[cadence][newPremium]) {
          tierKey = newPremium;
        }
        break;
    }

    return tierPrices[cadence][tierKey];
  }

  public getDiscountTierPrice({
    tier,
    cadence,
    paywallCouponConfig,
    usdPrice
  }: GetDiscountTierPriceParams): number {
    const countrySettings = usdPrice ? countrySettingOptions.US : this.countrySettings;

    const regularPrice = shouldOfferLocalizedPrices()
      ? this.getLocalizedTierPrice({ tier, cadence, countrySettings })
      : this.getTierPrice({ tier, cadence, countrySettings });

    if (!paywallCouponConfig) {
      return regularPrice;
    }

    const { discountType } = paywallCouponConfig;
    const discount =
      getCouponDiscount({ paywallCouponConfig, tier, cadence, platform: Platform.WEB }) ?? 0;

    switch (discountType) {
      case 'amount':
        return Math.floor(regularPrice - discount);
      case 'percentage':
        return Math.floor(regularPrice - (regularPrice * discount) / 100);
      default:
        return regularPrice;
    }
  }

  private getLocalizedTierPrice({ tier, cadence, countrySettings }: GetTierPriceParams): number {
    const localizedPrice = countrySettings.subscription.localizedTierPrice?.[cadence]?.[tier];

    if (Number.isSafeInteger(localizedPrice)) {
      return localizedPrice;
    }

    return this.getTierPrice({ tier, cadence, countrySettings });
  }

  @action
  public getGeoSubAmount({
    cadence = Cadence.MONTHLY,
    isNextMonthPrice,
    tier,
    usdPrice = false,
    coupon
  }: {
    cadence?: Cadence;
    isNextMonthPrice?: boolean;
    tier?: SubscriptionTier | null;
    usdPrice?: boolean;
    coupon?: PaywallCouponConfig;
  } = {}): number {
    const countrySettings = usdPrice ? countrySettingOptions.US : this.countrySettings;

    if (this.shouldOfferPaywallDiscount && tier && coupon) {
      return this.getDiscountTierPrice({ tier, cadence, paywallCouponConfig: coupon });
    }

    if (tier && shouldOfferLocalizedPrices()) {
      return this.getLocalizedTierPrice({ tier, cadence, countrySettings });
    }

    if (tier) {
      return this.getTierPrice({ tier, cadence, countrySettings });
    }

    if (this.isSubTrial) {
      return this.subUpgrade.newPrice;
    }

    if (this.isLegacySubscriber) {
      return countrySettings.subscription.price[0];
    }

    if (isNextMonthPrice) {
      return countrySettings.subscription.price[0];
    }

    if (this.shouldOfferCouponSku && cadence === Cadence.MONTHLY) {
      return countrySettings.subscription.price[1];
    }

    return countrySettings.subscription[cadence][0];
  }

  @action
  public getGeoSubOrderSku({
    cadence = Cadence.MONTHLY,
    tier
  }: {
    cadence?: Cadence;
    tier?: SubscriptionTier;
  } = {}): string {
    const currencyCode = this.geoSubCurrencyCode.toLowerCase();

    if (tier) {
      return getTierSku({ tier, cadence });
    }

    if (this.isSubTrial) {
      return this.subUpgrade.orderSku;
    }

    if (this.isLegacySubscriber) {
      return `com.invoicesimple.web.monthly1.${currencyCode}`;
    }

    if (this.shouldOfferCouponSku && cadence === Cadence.MONTHLY) {
      return `com.invoicesimple.web.monthly.3.${currencyCode}.1.coupon`;
    }

    const skuMeta = cadence === Cadence.MONTHLY ? `monthly.3` : `annual.30`;
    return `com.invoicesimple.web.${skuMeta}.${currencyCode}`;
  }

  @action
  public initEmail(value: string): void {
    this.loginData.email = value;
    this.signupData.email = value;
  }

  handleWelcomeAlertDismiss = async () => {
    this.hasWelcomeAlertCookie = true;
    this.trackAppEventViaApi('welcome-banner-dismiss');
    setCookie('isWelcomeV1', 1);
  };

  public handleInvoicesCompleted(): void {
    setCookie('invoicesCompleted', ++this.invoicesCompleted);
  }

  @action
  public async setProvidedRating(): Promise<void> {
    this.meta.hasProvidedRating = true;
    return UserAuth.saveUser(this);
  }

  @action
  public signup(countryCode?: string) {
    return UserAuth.signup(this, countryCode);
  }
  @action
  public async smsSignup(token: string): Promise<boolean> {
    return UserAuth.smsSignup(this, token);
  }
  @action
  public async login({
    postLoginReturnPath,
    withSubscriptionTransfer,
    onboardingV1Enabled
  }: {
    postLoginReturnPath?: string;
    withSubscriptionTransfer?: boolean;
    onboardingV1Enabled?: boolean;
  }): Promise<string | undefined> {
    return UserAuth.login({
      user: this,
      postLoginReturnPath,
      withSubscriptionTransfer,
      onboardingV1Enabled
    });
  }
  @action
  public async logout(): Promise<void> {
    return UserAuth.logout(this);
  }

  @action
  public update(data: Parse.Object) {
    try {
      this.id = data.id;
      this.sessionToken = this.get(data, 'sessionToken');
      this.email = this.get(data, 'email');
      this.firstName = this.get(data, 'firstName');
      this.lastName = this.get(data, 'lastName');
      this.createdAt = new Date(this.get(data, 'createdAt'));
      this.updatedAt = new Date(this.get(data, 'updatedAt'));
      this.username = this.get(data, 'username');
      this.meta = this.get(data, 'meta');
      this.countryCode = this.get(data, 'countryCode');
      this.onboardingCompleted = this.get(data, 'onboardingCompleted');

      const account = this.get(data, 'account');

      if (account && account.id) {
        this.accountId = account.id;
      }
      if (account && account.objectId) {
        this.accountId = account.objectId;
      }

      if (this.sessionToken) {
        setCookie('sessionToken', this.sessionToken);
      }
    } catch (error) {
      this.trackError('user-update', error);
      throw error;
    }
  }

  @computed
  get passwordResetDataValid(): boolean {
    const e = this.passwordResetData.email;
    return e && e.length && e.match(/@/);
  }

  public getApiReqOpts(): ApiRequestOptions {
    const baseURL = environmentStore.appApiUrl;
    const headers = removeUndefinedOrNull({
      'x-is-user': this.id, // deprecated
      'x-is-account': this.accountId, // deprecated
      'x-is-app': 'app.invoicesimple.com',
      'x-is-country': this.countryCode,
      'x-is-installation': environmentStore.installationId,
      'x-is-platform': 'web',
      'x-is-version': environmentStore.getReactAppVersion(),
      'x-parse-session-token': this.sessionToken
    });
    return { baseURL, headers };
  }

  public getApiV3ReqOpts(): ApiRequestOptions {
    const baseURL = environmentStore.appApiUrl;
    const headers = removeUndefinedOrNull({
      'x-is-account': this.accountId, // deprecated
      'x-is-app': 'app.invoicesimple.com',
      'x-is-country': this.countryCode,
      'x-is-installation': environmentStore.installationId,
      'x-is-platform': 'web',
      'x-is-session': this.sessionToken,
      'x-is-user': this.id, // deprecated
      'x-is-version': environmentStore.getReactAppVersion()
    });
    return { baseURL, headers };
  }

  // upgrades
  // always return SubscriptionUpgradeModel
  @computed
  get subUpgrade(): SubscriptionUpgradeModel {
    return new SubscriptionUpgradeModel({} as ApiSubscriptionUpgrade);
  }

  // subs
  // IS Subscription
  // TODO refactor this into SubscriptionListModel

  @computed
  get freeQuota(): number {
    return this.isGuest ? this.freeQuotaAnonymous : this.freeQuotaLoggedIn;
  }

  @computed
  get docQuota(): number {
    if (this.isSubTier(SubscriptionTier.STARTER) || this.isSubTier(SubscriptionTier.ESSENTIALS)) {
      return this.tierOneMonthlyQuota;
    }
    if (this.isSubTier(SubscriptionTier.PLUS)) {
      return this.plusMonthlyQuota;
    }
    if (this.isSubPaid) return -1;
    return this.freeQuota;
  }

  @computed
  get freeExportQuota(): number {
    return this.isGuest ? this.freeExportQuotaAnonymous : this.freeExportQuotaLoggedIn;
  }

  // amount of free invoices/estimates a user can create while on a free trial or anonymous
  @computed
  get freeQuotaAnonymous(): number {
    return InvoiceCreationLimit.FREE_LIMIT_ANONYMOUS;
  }

  // amount of free invoices/estimates a user can create while on a free trial and logged in
  @computed
  get freeQuotaLoggedIn(): number {
    return InvoiceCreationLimit.FREE_LIMIT_LOGGED_IN;
  }

  // amount of invoices an user can export / send while on a free trial or anonymous
  @computed
  get freeExportQuotaAnonymous(): number {
    return ExportLimit.FREE_EXPORT_LIMIT_ANONYMOUS;
  }

  // amount of invoices an user can export / send while on a free trial and logged in
  @computed
  get freeExportQuotaLoggedIn(): number {
    return ExportLimit.FREE_EXPORT_LIMIT_LOGGED_IN;
  }

  @computed
  get tierOneMonthlyQuota(): number {
    return InvoiceCreationLimit.TIER_1_LIMIT;
  }

  @computed
  get plusMonthlyQuota(): number {
    return InvoiceCreationLimit.TIER_2_LIMIT;
  }

  // active paid subs
  @computed
  get activePaidSubs(): SubscriptionModel[] {
    return filter(this.subs, { isActive: true, isPaid: true });
  }

  // paid subs
  @computed
  get paidSubs(): SubscriptionModel[] {
    return filter(this.subs, { isTrial: false });
  }

  // trial subs
  @computed
  get trialSubs(): SubscriptionModel[] {
    return sortBy(filter(this.subs, { isTrial: true }), (s) => {
      return s.expiryTimestamp;
    });
  }
  @computed
  get activeTrialSubs(): SubscriptionModel[] {
    return filter(this.subs, { isActive: true, isTrial: true });
  }
  @computed
  get trialSub(): SubscriptionModel | undefined {
    return last(this.trialSubs);
  }

  // boolean sub status
  @computed
  get isSubPaid(): boolean {
    return this.activePaidSubs.length > 0;
  }
  @computed
  get isSubTrial(): boolean {
    return !this.isSubPaid && this.trialSubs.length > 0;
  }
  @computed
  get isSubTrialActive(): boolean {
    // Trial is active if there is an active trial sub or if the user NEVER had a paid sub
    const isTrial = this.hasActiveTrialSub || !this.hadPaidSubsInThePast;
    return !this.isSubPaid && isTrial;
  }
  @computed
  get hasActiveTrialSub(): boolean {
    return this.activeTrialSubs.length > 0;
  }
  @computed
  get isInFreeMode(): boolean {
    return !this.isSubPaid && !this.hasActiveTrialSub;
  }

  @computed
  get isWithinFreeQuota(): boolean {
    return this.docsCount < this.freeQuota;
  }
  @computed
  private get isWithinTierOneMonthlyQuota(): boolean {
    // ignore monthly quota check if not starter & essentials tier
    if (!this.isSubTier(SubscriptionTier.STARTER) && !this.isSubTier(SubscriptionTier.ESSENTIALS)) {
      return true;
    }
    return this.docsCountLastMonth < this.tierOneMonthlyQuota;
  }
  @computed
  private get isWithinPlusMonthlyQuota(): boolean {
    // ignore monthly quota check if not plus tier
    if (!this.isSubTier(SubscriptionTier.PLUS)) {
      return true;
    }
    return this.docsCountLastMonth < this.plusMonthlyQuota;
  }
  @computed
  get isWithinTieredMonthlyQuota(): boolean {
    return this.isWithinTierOneMonthlyQuota && this.isWithinPlusMonthlyQuota;
  }
  @computed
  get docsRemaining(): number {
    // TODO: update this when we work on doc limit banner for paid users
    return Math.max(0, this.freeQuotaLoggedIn - this.docsCount);
  }

  @computed
  get isSubPremiumTierAnnual(): boolean {
    return this.isSubPaid && this.activePaidSubs.some((sub) => sub.isPremiumTier && sub.isAnnual);
  }

  @computed
  get isPremiumOrLegacyPremiumSub(): boolean {
    return (
      this.isSubTier(SubscriptionTier.PREMIUM) || this.isSubTier(SubscriptionTier.PREMIUM_LEGACY)
    );
  }

  @computed
  get isSubNewPremiumTier(): boolean {
    return this.isSubPaid && this.activePaidSubs.some((sub) => sub.isNewPremiumTier);
  }

  @computed
  get isSubNewTier(): boolean {
    return (
      this.isSubTier(SubscriptionTier.ESSENTIALS) ||
      this.isSubTier(SubscriptionTier.PLUS) ||
      this.isSubNewPremiumTier
    );
  }

  @computed
  get isSubWebMobile(): boolean {
    return (
      this.isSubTier(SubscriptionTier.PREMIUM) ||
      (this.isSubPaid && this.activePaidSubs.some((sub) => sub.isWebMobile))
    );
  }
  @computed
  get hasMobileOnlySub(): boolean {
    return this.isSubPaid && this.activePaidSubs.some((sub) => sub.isMobileOnly);
  }
  @computed
  get isSubAndroid(): boolean {
    return this.isSubPaid && this.activePaidSubs.some((sub) => sub.isAndroid);
  }
  @computed
  get isSubiOS(): boolean {
    return this.isSubPaid && this.activePaidSubs.some((sub) => sub.isiOS);
  }
  @computed
  get activeSubscriptionTier(): SubscriptionTier | null {
    const isSubPremiumLegacyTier =
      this.isSubPaid && this.activePaidSubs.some((sub) => sub.isPremiumLegacyTier);
    const isSubPremiumTier = this.isSubPaid && this.activePaidSubs.some((sub) => sub.isPremiumTier);
    const isSubProTier = this.isSubPaid && this.activePaidSubs.some((sub) => sub.isWebProTier);
    const isSubPlusTier = this.isSubPaid && this.activePaidSubs.some((sub) => sub.isPlusTier);

    const filteredActivePaidSubs = this.activePaidSubs.filter((sub) => !sub.isWebPaymentsTier);

    const isSubStarterTier =
      this.isSubPaid &&
      !!filteredActivePaidSubs.length &&
      filteredActivePaidSubs.every((sub) => sub.isWebStarterTier);
    const isSubPaymentsTier =
      this.isSubPaid && this.activePaidSubs.every((sub) => sub.isWebPaymentsTier);
    const isSubEssentialsTier =
      this.isSubPaid &&
      !!filteredActivePaidSubs.length &&
      filteredActivePaidSubs.every((sub) => sub.isEssentialsTier);

    switch (true) {
      case isSubPremiumLegacyTier:
        return SubscriptionTier.PREMIUM_LEGACY;
      case isSubPremiumTier:
        return SubscriptionTier.PREMIUM;
      case isSubPaymentsTier:
        return SubscriptionTier.PAYMENTS_TIER;
      case isSubProTier:
        return SubscriptionTier.PRO;
      case isSubPlusTier:
        return SubscriptionTier.PLUS;
      case isSubStarterTier:
        return SubscriptionTier.STARTER;
      case isSubEssentialsTier:
        return SubscriptionTier.ESSENTIALS;
      default:
        return null;
    }
  }

  public isSubTier(tier: SubscriptionTier): boolean {
    return this.activeSubscriptionTier === tier;
  }

  @computed
  get isDocLimitedTier(): boolean {
    return (
      this.isSubTier(SubscriptionTier.STARTER) ||
      this.isSubTier(SubscriptionTier.ESSENTIALS) ||
      this.isSubTier(SubscriptionTier.PLUS)
    );
  }

  @computed
  get activeSubscriptionCadence(): Cadence | null {
    // Non-paid subs return null
    if (!this.isSubPaid) {
      return null;
    }
    // Is it an annual tiered sub?
    const isAnnualSub: boolean = this.lastActiveSub?.planInterval === 'annual';
    if (isAnnualSub) {
      return Cadence.ANNUAL;
    }
    // Is there "annual" on the SKU name?
    const isAnnualSku: boolean = !!this.lastActiveSub?.orderSku?.includes('annual');
    if (isAnnualSku) {
      return Cadence.ANNUAL;
    }

    // Falls back to monthly
    return Cadence.MONTHLY;
  }

  @action
  public canCreateNewDoc(docType?: DocType): boolean {
    if (docType === DocTypes.DOCTYPE_ESTIMATE) return true;
    if (this.isInFreeMode) return this.isWithinFreeQuota;

    return this.hasActiveTrialSub || (this.isSubPaid && this.isWithinTieredMonthlyQuota);
  }

  @action
  public canUseFeature(feature: FeatureName): boolean {
    return hasFullAccessToFeature({ feature, user: this });
  }

  private async canSendDocument(): Promise<boolean> {
    if (this.isSubActive) return true;

    const sentDocuments = await getSentDocuments(this.accountId);
    return sentDocuments.length < this.freeExportQuota;
  }

  /* Declare signature to enable condition return type based on the action parameter */
  canExportDocument<T extends InvoiceExportAction>(
    action: T
  ): T extends InvoiceExportAction.EMAIL ? Promise<boolean> : boolean;
  public canExportDocument(action: InvoiceExportAction): boolean | Promise<boolean> {
    switch (action) {
      case InvoiceExportAction.PRINT:
      case InvoiceExportAction.GET_LINK:
        return this.isSubActive || (!this.isGuest && this.isWithinFreeQuota);
      case InvoiceExportAction.EMAIL:
        return this.canSendDocument().then((canSend) => canSend);
      case InvoiceExportAction.PDF:
        return this.isSubActive;
    }
  }

  @computed
  get shouldOfferNewTieredPricing(): boolean {
    // Users who should see the new paywall: new users or already new tiered users
    return !this.isSubPaid || this.isSubNewTier;
  }

  @computed
  get shouldOfferNewDiscountedTieredPricing(): boolean {
    return this.shouldOfferNewTieredPricing && this.shouldOfferPaywallDiscount;
  }

  @computed
  get shouldOfferOldTieredPricing(): boolean {
    return !this.shouldOfferNewTieredPricing && (!this.isSubPaid || !!this.activeSubscriptionTier);
  }

  @computed
  get shouldOfferPremiumLegacyPricing(): boolean {
    return flagsmith.hasFeature('web_upgrade_premium_legacy');
  }

  @computed
  get shouldShowNavUpgradeBtn(): boolean {
    return (
      !this.isSubPremiumTierAnnual &&
      (!this.isSubPaid ||
        this.shouldOfferNewTieredPricing ||
        this.shouldOfferOldTieredPricing ||
        this.shouldOfferPremiumLegacyPricing) &&
      !this.isPremiumOrLegacyPremiumSub
    );
  }

  @computed
  get paywallType(): PaywallType {
    if (this.shouldOfferPremiumLegacyPricing) {
      return PaywallType.PREMIUM_LEGACY;
    }
    if (showPremiumOnlyPaywall()) {
      return PaywallType.PREMIUM_ONLY;
    }
    if (isFreeTierPaywallEnabled()) {
      return PaywallType.TIERED_V3;
    }
    if (this.shouldOfferNewDiscountedTieredPricing) {
      return PaywallType.DISCOUNTED;
    }
    if (this.shouldOfferNewTieredPricing) {
      return PaywallType.TIERED_V2;
    }
    if (this.shouldOfferOldTieredPricing) {
      return PaywallType.TIERED_V1;
    }
    if (this.isSubPaid) {
      return PaywallType.PRO;
    }
    if (!this.isSubPaid && this.isSubTrial) {
      return PaywallType.TRIAL;
    }
    return PaywallType.FREE;
  }
  @computed
  get isNew(): boolean {
    return !!this.id;
  }
  @computed
  get isGuest(): boolean {
    return !(!!this.username && this.username.match(/new_/));
  }
  @computed
  get adminUrl() {
    return this.id && `${environmentStore.appAdminUrl}/user/${this.id}`;
  }
  @computed
  get accountAdminUrl() {
    return this.accountId && `${environmentStore.appAdminUrl}/account/${this.accountId}`;
  }
  @computed
  get displayName() {
    if (this.firstName) {
      return [this.firstName, this.lastName].join(' ');
    } else {
      return this.email;
    }
  }

  shouldShowReactivationPrompt(): boolean {
    const wasShownAlready = localStorage.getItem('reactivationPromptShown');
    const isChurnedUser = !this.isSubPaid && this.hadPaidSubsInThePast;
    return !wasShownAlready && isChurnedUser && !this.isSubTrialActive && !isReferralShareRef();
  }

  @action
  public setReactivationPromptShown(): void {
    localStorage.setItem('reactivationPromptShown', 'true');
  }

  isSubscribedToPlan(tier: SubscriptionTier, planInterval: Cadence): boolean {
    return (
      this.activeSubscriptionTier === tier &&
      this.lastActivePaidWebSub(tier)?.planInterval === planInterval
    );
  }

  // determine if ab test with name should be displayed
  // will return true if not active
  // 0 = control
  // 1 = variant
  isAbTestVariant(name: string): boolean {
    return this.abTests?.[name] > 0;
  }

  isAbTestControl(name: string): boolean {
    return !this.isAbTestVariant(name);
  }

  getAbTestVariant(name: string): number {
    return this.abTests?.[name];
  }

  isAssignedAbTest(name: string): boolean {
    return Object.prototype.hasOwnProperty.call(this.abTests, name);
  }

  @computed
  get parseData() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      username: this.username,
      password: this.password,
      app: environmentStore.appHost,
      countryCode: this.countryCode,
      meta: this.meta
    };
  }

  public async getPurchaseEmail() {
    const updatedUser = await parseUserFetchData(this.id!);

    const meta: UserMeta = updatedUser?.get('meta') || {};
    return meta.signupEmail;
  }

  @action
  public updateRequestReviewSetting(): void {
    try {
      const requestReviewEnabled = this.settingList.getSetting(SettingKeys.RequestReviewEnabled);
      const hasRequestReviewAccess = hasFullAccessToFeature({
        feature: FeatureName.REQUEST_REVIEW,
        user: this
      });

      // set request.review.enabled setting to false if user doesn't have access
      if (requestReviewEnabled?.valBool === true && !hasRequestReviewAccess) {
        requestReviewEnabled.setValue(false);
      }
    } catch (error) {
      this.trackError('user-updateRequestReview', error);
    }
  }

  @action
  public async syncSubscriptions(): Promise<void> {
    if (!this.accountId) {
      return;
    }

    try {
      const [result, subscriptions] = await Promise.all([
        getSubscriptionStatusV3(this),
        getSubscriptionsV3(this)
      ]);
      const {
        allDocsCount,
        docsThresholdCount,
        hadPaidSubsInThePast,
        docsCountLastMonth,
        accountCreationTimestamp,
        hasFreeSub,
        subscriptionTier,
        orderSku
      } = result;
      this.isSubActive = result.active;
      this.subs = subscriptions.map((s) => new SubscriptionModel(s as ApiSubscription));
      this.accountCreationTimestamp = accountCreationTimestamp;
      this.allDocsCount = allDocsCount ?? 0;
      this.docsCount = docsThresholdCount;
      this.docsCountLastMonth = docsCountLastMonth || 0;
      this.updateRequestReviewSetting();
      this.hadPaidSubsInThePast = !!hadPaidSubsInThePast;
      this.hasFreeTrialSubscription = hasFreeSub;
      this.subscriptionTier = subscriptionTier || null;
      this.subscriptionOrderSku = orderSku || null;

      updateBrazeCustomAttributes(this.forBraze());
    } catch (err) {
      this.trackError('user-sync-subs', err);
      this.subs = [];
      this.accountCreationTimestamp = null;
    }
  }

  @action
  public async syncCoupons(): Promise<void> {
    try {
      const coupon = await getCouponInfo(this);

      this.paywallCouponInfo = coupon ? coupon : undefined;
      this.paywallCouponStatus = coupon ? 'valid' : undefined;
    } catch (err) {
      const error = err as AxiosError<ApiCouponError>;

      this.paywallCouponInfo = undefined;
      const responseData = error.response?.data;

      if (!responseData) {
        this.paywallCouponStatus = 'invalid';
        return;
      }

      if (responseData?.code === 'INVALID_COUPON') {
        this.paywallCouponStatus = 'invalid';
      } else if (responseData?.code === 'EXPIRED_COUPON') {
        this.paywallCouponStatus = 'expired';
      } else if (
        responseData?.code === 'USER_NOT_ELIGIBLE' ||
        responseData?.reasonCode === 'FORBIDDEN'
      ) {
        this.paywallCouponStatus = 'not_eligible';
      } else {
        this.trackError('user-sync-coupons', err?.response?.data || err);
        this.paywallCouponStatus = 'invalid';
      }
    }
  }

  @action
  public async syncReferrals(forceFetch?: boolean): Promise<void> {
    try {
      const referral = await getAdvocateReferralCode(this, forceFetch);
      this.advocateReferralCode = referral;
    } catch (err) {
      this.trackError('user-sync-referrals', err);
      this.advocateReferralCode = null;
    }
  }

  @action
  public incDocsCount(): void {
    this.docsCount++;
    this.docsCountLastMonth++;
  }

  @action
  public incInvoiceEstimatesCount(): void {
    this.allDocsCount++;
  }

  public getParseUser(): Parse.User | undefined {
    if (this.id) {
      const user = new Parse.User();
      user.id = this.id;
      return user;
    }
    return undefined;
  }

  public getParseAccount(): Parse.Object {
    if (this.accountId) {
      const account = new Account();
      account.id = this.accountId;
      return account;
    }
    throw new Error('Account Required');
  }

  trackError(type: string, err: Error | Parse.Error | AxiosError) {
    console.log('trackError', type, err);
    const errMessage = axios.isAxiosError(err)
      ? `${err.message}: ${err.response?.data}`
      : err.message;
    const errorData = { type, message: errMessage, code: (err as Parse.Error).code };
    const ignoredErrorMessages = [
      'Request aborted',
      'Object not found.',
      'Invalid session token',
      'Session token is expired.',
      'XMLHttpRequest failed: "Unable to connect to the Parse API"',
      'Account already exists for this username.'
    ];

    // Ignores reporting errors with messages in array.
    // Reason for using message instead of the type
    // is because some error types may have different messages
    if (ignoredErrorMessages.includes(err.message)) return;

    errorListModel.pushError(errorData);
    Rollbar.trackError(type, err, errorData);
    this.trackAppEventViaApi('error', errorData);
  }

  /**
   * @deprecated Use user.events.trackX instead
   * TO-DO: Remove this method and call user.events.trackEvent method directly from consumers
   */
  trackAppEventViaApi<T extends WebEventName>(name: T, data?: GenericEventMeta<T>) {
    this.events.trackEvent(name, data);
  }

  // build event data and merge with param
  @computed
  get defaultEventData(): any {
    return {
      abTests: this.abTests || {},
      app: environmentStore.appHost,
      path: window.location.pathname,
      url: window.location.href,
      platform: Platform.WEB,
      newSession: this.isSessionNew,
      requestId: this.requestId,
      country: this.countryCode || null,
      connectionType: environmentStore.connectionType
    };
  }

  trackAdwords(name: 'web-purchase' | 'web-invoice-complete') {
    const id = '1006814914';
    const label = {
      'web-purchase': 'TzGFCJCo4nMQwo2L4AM',
      'web-invoice-complete': '4V20CNnG-3MQwo2L4AM'
    }[name];
    if (label) {
      const image = new Image(1, 1);
      image.src = `//www.googleadservices.com/pagead/conversion/${id}/?label=${label}&amp;guid=ON&amp;script=0`;
    }
  }

  public handleError = (name: string, error: Parse.Error | AxiosError) => {
    if (environmentStore.isSnapshot()) {
      return;
    }
    this.trackError(name, error);
  };

  // mobileSync
  @action
  public mobileSync(): void {
    const url = '/api-im/v1/sync-push-notif';
    const data = {
      accountId: this.accountId,
      fromInstallation: `${environmentStore.appHost}.install`,
      timestamp: new Date().valueOf(),
      pushType: 4,
      pushVer: 1
    };
    this.startMobileSync();
    axios
      .post(url, data, this.getApiReqOpts())
      .then(() => {
        this.stopMobileSync();
      })
      .catch((err) => {
        this.trackError('mobile-sync', err);
        this.stopMobileSync();
      });
  }

  @action.bound
  public async loadUserEntities(): Promise<void> {
    if (environmentStore.isSnapshot()) {
      this.isLoaded = true;
      return Promise.resolve();
    }

    this.loadPromise = Promise.all([
      this.settingList.load(),
      this.syncSubscriptions(),
      this.syncCoupons(),
      this.syncReferrals()
    ]);

    await this.loadPromise;

    this.isLoaded = true;
    this.trackAppEventViaApi('app-ready');

    // override abtest values
    this.setAbtestOverrides(abTestOverrides);
    updateAnalyticsProviders({ user: this });

    const hasProfitCalculatorParams = !!(
      getURLQueryParam(URLQueryParamKeys.LABOR_COSTS) ||
      getURLQueryParam(URLQueryParamKeys.MATERIAL_COSTS)
    );

    // check subscription when we get all the details
    if (
      !this.canCreateNewDoc(location.docType) &&
      location.isCreatingDocument &&
      !hasProfitCalculatorParams
    ) {
      if (process.env.REACT_APP_DOC_LIST_NEXTJS_MIGRATION_ENABLED === 'true') {
        window.location.assign(`/invoices`);
        return;
      }
      location.nav('invoiceList');
    }
  }

  public setAbtestOverrides(overrides: string[]) {
    if (!this.subs.find((s) => s.isPaid)) {
      overrides.forEach((abTestName) => {
        if (!Object.prototype.hasOwnProperty.call(this.abTests, abTestName)) {
          this.abTests[abTestName] = Math.floor((Math.random() * 100) % 2);
        }
      });
    }
  }

  public forBraze() {
    const customAttributes = {
      [UserAttributes.IS_SUBCRIPTION_ACTIVE]: this.isSubActive,
      [UserAttributes.HAS_FREE_TRIAL_SUBSCRIPTION]: this.hasFreeTrialSubscription
    };

    return removeUndefinedOrNull(customAttributes);
  }

  public forIntercom() {
    return removeUndefinedOrNull({
      'app-identifier': environmentStore.appHost,
      'app-name': environmentStore.appName,
      app_id: environmentStore.intercomAppId,
      user_id: this.id,
      created_at: this.createdAt && Math.round(this.createdAt.getTime() / 1000),
      last_seen: Math.round(Date.now() / 1000),
      is_guest: this.isGuest,
      email: this.email,
      name: this.displayName,
      first_name: this.firstName,
      last_name: this.lastName,
      admin: this.adminUrl,
      company: !!this.accountId && { company_id: this.accountId, admin: this.accountAdminUrl },
      ...flattenExperiments(this.abTests)
    });
  }

  public forRollbar() {
    return {
      payload: {
        request_id: this.requestId,
        installation_id: environmentStore.installationId,
        person: {
          id: this.id,
          account_id: this.accountId,
          email: this.email
        }
      }
    };
  }

  @action
  private startMobileSync(): void {
    this.isMobileSyncing = true;
  }

  @action
  private stopMobileSync() {
    this.mobileSyncCount += 1;
    this.isMobileSyncing = false;
  }

  private getDefault(name: string) {
    return { subscriptions: [], meta: {} }[name];
  }

  @action
  public async setSurchargeConsentGiven(consentMessage: string) {
    const data = [
      {
        consentType: ConsentType.PASSING_FEES_SURCHARGE,
        consentStatus: ConsentStatus.CONSENT_GIVEN,
        consentValue: surchargeConsentVersion,
        consentMessage
      }
    ];
    try {
      if (!this.isSurchargeConsentGiven) {
        await saveConsentData({
          user: this,
          data
        });
        this.isSurchargeConsentGiven = true;
      }
    } catch (error) {
      Rollbar.trackError('Error while saving consent details', error, {
        accountId: this.accountId,
        data,
        createdAt: new Date().toISOString()
      });
      throw error;
    }
  }

  @action
  public async getIsSurchargeConsentGiven(): Promise<boolean> {
    const consentType = ConsentType.PASSING_FEES_SURCHARGE;
    const consentValue = surchargeConsentVersion;
    try {
      if (!this.isSurchargeConsentGiven) {
        this.isSurchargeConsentGiven = await isConsentGivenOrImplicit({
          consentType,
          consentValue,
          user: this
        });
      }
    } catch (error) {
      Rollbar.trackError('Error while fetching consent details', error, {
        accountId: this.accountId,
        consentType,
        consentValue
      });
    }
    return this.isSurchargeConsentGiven;
  }
}
